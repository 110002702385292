import { render, staticRenderFns } from "./invite.vue?vue&type=template&id=043d5cb9&scoped=true&"
var script = {}
import style0 from "./invite.vue?vue&type=style&index=0&id=043d5cb9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "043d5cb9",
  null
  
)

export default component.exports